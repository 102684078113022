<template>
  <BaseModal
    ref="modal"
    title="Schattingen"
    max-width="tw-max-w-4xl"
    @hide="$emit('close')"
  >
    <div class="tw-mb-7.5 tw-p-6 tw-rounded-md tw-shadow-card tw-bg-white">
      <FormulateForm
        #default="{ isLoading }"
        v-model="values"
        name="createEstimateForm"
        title="Schattingsrapport toevoegen"
        invalid-message="Gelieve de verplichte velden correct in te vullen."
        @submit="submit"
      >
        <FormulateInput
          type="text"
          name="title"
          label="Titel schattingsrapport"
          :value="defaultTitle"
          validation="required:trim"
          outer-class="tw-m-0"
        />
        <div class="tw-my-4 tw-grid sm:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="number"
            name="price_per_square_meter_min"
            label="Minimumprijs m²"
            placeholder="euro / m²"
            validation="bail|required|number|min:0"
            step="1"
            min="0"
            lang="nl"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
            :element-class="['tw-flex tw-items-center']"
            outer-class="tw-my-0"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>

          <FormulateInput
            type="number"
            name="price_per_square_meter_max"
            label="Maximumprijs m²"
            placeholder="euro / m²"
            validation="bail|required|number|min:0"
            step="1"
            min="0"
            lang="nl"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
            :element-class="['tw-flex tw-items-center']"
            outer-class="tw-my-0"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
          <FormulateInput
            type="select"
            name="language"
            label="Taal"
            :options="USER_LANG_OPTIONS"
            validation="required"
            outer-class="tw-m-0"
          />
        </div>

        <FormulateErrors class="tw-text-right" />

        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full sm:tw-w-auto']"
            outer-class="tw-mt-2 tw-w-full sm:tw-w-auto"
          >
            <i
              :class="[
                'fas tw-mr-2',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Schattingsrapport toevoegen
          </FormulateInput>
        </div>
      </FormulateForm>
    </div>

    <div v-if="estimates.length" class="tw-grid sm:tw-grid-cols-2 tw-gap-8">
      <div
        v-for="estimate in estimates"
        :key="estimate.id"
        class="tw-p-4 tw-relative tw-flex tw-flex-col tw-justify-between tw-gap-2 tw-rounded-md tw-shadow-card tw-bg-white"
      >
        <div class="tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2">
          <button
            type="button"
            title="Verwijderen"
            class="action-button tw-bg-white tw-text-success tw-border-success hover:tw-bg-success"
          >
            <i class="fas fa-pencil" />
          </button>
          <button
            type="button"
            title="Verwijderen"
            :disabled="estimate.deleting"
            class="action-button tw-bg-white tw-text-danger tw-border-danger hover:tw-bg-danger"
            @click="deleteEstimate(estimate)"
          >
            <i
              :class="[
                'fas',
                estimate.deleting
                  ? 'fa-spinner-third fa-spin'
                  : 'fa-trash'
              ]"
            />
          </button>
        </div>

        <component
          :is="estimate.property_file ? 'a' : 'h4'"
          :title="estimate.title"
          :download="estimate.title"
          :href="estimate.property_file"
          class="tw-my-0 tw-font-light tw-flex-grow"
        >
          {{ estimate.title }}
        </component>

        <div class="tw-flex-col tw-gap-2">
          <div class="tw-flex tw-justify-between tw-items-center tw-gap-2">
            <h5 class="tw-my-0">Intrinsieke waarde:</h5>
            <span>{{ currency(estimate.value_intrinsic_override || estimate.value_intrinsic) }}</span>
          </div>
          <div class="tw-flex tw-justify-between tw-items-center tw-gap-2">
            <h5 class="tw-my-0">Advieswaarde:</h5>
            <span>{{ currency(estimate.value_advice_override || estimate.value_advice) }}</span>
          </div>
        </div>

        <div v-if="estimate.created_by" class="tw-text-[11px] tw-italic tw-text-right">
          Gemaakt door {{ estimate.created_by.first_name }} {{ estimate.created_by.last_name }}
          om {{ estimate.created_on | date-day }}
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { errorModal, questionModal, successModal } from '@/modalMessages'
import { USER_LANG_OPTIONS, formatDateTime, currency } from '@/utils/helpers'
import { getPropertyEstimates, createPropertyEstimate, deletePropertyEstimate } from '@/services/properties'

export default {
  name: 'PropertyEstimatesModal',
  constants: {
    USER_LANG_OPTIONS
  },
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      values: {},
      estimates: []
    }
  },
  computed: {
    defaultTitle () {
      if (!this.property?.address) return 'Schattingsrapport'
      return `Schattingsrapport ${this.property.address}`
    }
  },
  methods: {
    currency,
    formatDateTime,

    show () {
      this.loadEstimates()
      this.$refs.modal.show()
    },

    async loadEstimates () {
      const response = await getPropertyEstimates(this.property.id)
      this.estimates = response?.data?.results || []
      return response
    },
    async submit (data) {
      try {
        const response = await createPropertyEstimate(this.property.id, data)
        await this.loadEstimates()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'createEstimateForm')
      }
    },
    async deleteEstimate (estimate) {
      try {
        const result = await questionModal('Weet je zeker dat je deze schatting wil verwijderen?')
        if (!result.value) return

        this.$set(estimate, 'deleting', true)
        const response = await deletePropertyEstimate(this.property.id, estimate.id)
        await this.loadEstimates()
        successModal(`${estimate.title} is verwijderd.`)
        return response
      } catch (error) {
        errorModal('Er is iets misgegaan bij het verwijderen van de schatting.')
        throw error
      } finally {
        this.$set(estimate, 'deleting', false)
      }
    }
  }
}
</script>
